const config = {
  firebase: {
    apiKey: "AIzaSyCv4XGuQtoGgUs4DSpIrhFKDRSyLRuvXUs",
    authDomain: "pameten-urnik.firebaseapp.com",
    projectId: "pameten-urnik",
    storageBucket: "pameten-urnik.appspot.com",
    messagingSenderId: "985838922008",
    appId: "1:985838922008:web:5d36305a8f0bb94632566d",
  },
};

export default config;
